<script>
import { required, minLength, maxLength, minValue, url, helpers } from 'vuelidate/lib/validators'

import SelectField from '@/components/general/SelectField'
import ImageUpload from '@/components/general/ImageUpload'
import Autocomplete from '@/components/general/Autocomplete'
import InputField from '@/components/general/InputField'
import TextArea from '@/components/general/TextArea'
import ManageContentStepOne from './ManageContentStepOne.vue'
import LastFiveSkillsUsed from '../skills/LastFiveSkillsUsed'

export default {
  name: 'ModalManageContent',
  components: {
    LastFiveSkillsUsed,
    SelectField,
    ImageUpload,
    Autocomplete,
    InputField,
    TextArea,
    ManageContentStepOne
  },
  props: {
    instanced: {
      type: Boolean,
      default: false
    },
    initialContent: {
      type: Number,
      default: null
    }
  },
  validations: {
    formData: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      link: {
        required,
        url
      },
      duration: {
        number: {
          required,
          minValue: minValue(1)
        }
      },
      level: {
        required
      },
      selectedSkills: {
        required
      },
      contentTypeId: {
        required
      },
      coverImage: {
        fileSize: helpers.withParams({
          size: 20971520
        },
        function (image) {
          if (!image || (this.selectedContent && image === this.selectedContent.coverImageURL) || typeof image === 'string') return true
          return this.isValidBlobSize(image, 20)
        })
      }
    }
  },
  data: () => ({
    dialog: true,
    msg: null,
    skillSearch: '',
    availableProfiles: null,
    availableLists: [],
    userWorkspaces: null,
    selectedContent: null,
    formData: {
      coverImage: null,
      coverImageURL: null,
      languageAlias: null,
      contentTypeId: null,
      title: null,
      description: null,
      link: null,
      duration: {
        number: null,
        unit: null
      },
      level: null,
      selectedSkills: [],
      selectedLists: [],
      workspaceId: null,
      list: null,
      isPrivate: false,
      removeImage: null
    },
    isClearable: true,
    isSaving: false,
    resizedLists: [],
    step: 1,
    totalSteps: 2
  }),
  computed: {
    selectedContentId () {
      return this.$route.params.contentId
    },
    selectedContentTypeId () {
      return this.formData.contentTypeId || this.initialContent
    },
    getContentTypes () {
      const rawContentTypes = this.$store.getters.getContentTypes
      return rawContentTypes.map(({ id, alias }) => ({ id, text: this.$t(`content.type:${alias}`) }))
    },
    selectedContentType () {
      const rawContentTypes = this.$store.getters.getContentTypes

      if (!this.selectedContentTypeId && this.selectedContent) {
        const content = this.selectedContent
        return {
          id: content.contentType.id,
          text: this.$t(`content.type:${content.contentType.alias}`),
          alias: content.contentType.alias
        }
      }

      const contentType = rawContentTypes.find(contentType => contentType.id === this.selectedContentTypeId)

      return contentType && {
        id: contentType.id,
        text: this.$t(`content.type:${contentType.alias}`),
        alias: contentType.alias
      }
    },
    getDefaultCoverImageBackground () {
      return this.selectedContentType && `/assets/images/background-${this.selectedContentType.alias}.png`
    },
    availableLanguages () {
      return [
        { text: this.$t('global.language:pt-BR'), value: 'pt-BR' },
        { text: this.$t('global.language:en'), value: 'en' }
      ]
    },
    userInfo () {
      return this.$store.getters.getUser
    },
    availableUnitsDuration () {
      const plural = 2
      return [
        { text: this.$tc('global:minutes', plural), value: 'minutes' },
        { text: this.$tc('global:hours', plural), value: 'hours' },
        { text: this.$tc('global:days', plural), value: 'days' }
      ]
    },
    availableLevels () {
      return [
        { text: this.$t('skill.modal.levels:basic'), value: 'basic' },
        { text: this.$t('skill.modal.levels:intermediate'), value: 'intermediate' },
        { text: this.$t('skill.modal.levels:advanced'), value: 'advanced' }
      ]
    },
    availableSkills () {
      // eslint-disable-next-line camelcase
      return this.$store.getters.getAvailableSkills.map(({ title, id }) => ({ title: title.length > 20 ? title.slice(0, 20) + '...' : title, id }))
    },
    canAddSkill () {
      return this.skillSearch && this.skillSearch.trim().length > 0 && this.skillSearch.length < 150 && this.availableSkills.every(item => item.title.toLocaleLowerCase() !== this.skillSearch.toLocaleLowerCase().trim())
    },
    resizedTitles () {
      return this.availableSkills.map(skill => {
        return skill.title.length < 20
          ? skill
          : { ...skill, title: skill.title.slice(0, 20) + '...' }
      })
        .concat(this.formData.selectedSkills)
        .filter((skill, index, arr) => arr.findIndex(s => s.id === skill.id) === index)
    },
    publishToIndividual () {
      return this.userWorkspaces && this.userWorkspaces.some(wk => wk.type === 'individual' && wk.id === this.formData.workspaceId)
    },
    workspaceId () {
      if (this.$route.params.workspaceId) {
        return parseInt(this.$route.params.workspaceId)
      }
      return false
    }
  },
  methods: {
    hydrateFormData () {
      this.$store.dispatch('attemptGetContentById', this.selectedContentId)
        .then(({ data: content }) => {
          this.formData.workspaceId = (content.workspace && content.workspace.id) || parseInt(this.$route.params.id)
          this.selectedContent = content
          this.formData.coverImage = content.coverImageURL
          this.formData.contentTypeId = content.contentType.id
          this.formData.languageAlias = content.languageAlias
          this.formData.title = content.title
          this.formData.description = content.description || ''
          this.formData.link = content.link
          this.formData.level = content.level
          this.formData.duration = {
            number: this.totalDurationByUnit(content.duration, content.durationType),
            unit: content.durationType
          }
          this.formData.isPrivate = this.publishToIndividual ? content.isPrivate : !content.isPrivate
          this.formData.selectedSkills = content.skills.map((skill) => { return { id: skill.id, title: this.longTextTreatment(skill.title, 20) } })
          this.formData.selectedLists = content.lists ? content.lists.map((skill) => skill.id) : []
        })
    },
    changeImage (image) {
      this.$v.formData.coverImage.$touch()
      this.formData.coverImage = image
      this.formData.coverImageURL = null
      this.formData.removeImage = null
    },
    removeImage () {
      this.formData.coverImage = null
      this.formData.coverImageURL = null
      this.formData.removeImage = true
    },
    closeContent () {
      this.$store.commit('clearRecentSkill')
      this.$v.$reset()
      !this.instanced && this.$router.push(this.getBackRoute())
      this.instanced && this.$emit('close')
    },
    updateLearningInProgress () {
      const availableStatus = ['conclusion', 'in_progress']
      availableStatus.map(status => {
        const params = {
          filter: { status },
          userId: this.$store.getters.getUser.userId
        }
        this.$store.dispatch('attemptGetFilteredUserLists', params)
        this.$store.dispatch('attemptGetFilteredUserContents', params)
      })
    },
    handleSkillAdd () {
      const skillTitle = this.skillSearch
      this.$store.dispatch('attemptSaveNewSkill', skillTitle)
        .then(({ data }) => {
          const parsedSkill = { id: data.id, title: skillTitle }
          this.formData.selectedSkills.push(parsedSkill)
          this.skillSearch = ''
        })
    },
    addNewSkill () {
      return `${this.$t('global:add')} "${this.skillSearch}"`
    },
    saveContent () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.isSaving = true
        const payload = {}
        const setCoverImageURL = (coverImageURL) => {
          if (coverImageURL && coverImageURL.trim().length > 0) {
            payload.coverImageURL = coverImageURL
          }
        }

        Object.keys(this.formData).forEach(key => {
          switch (key) {
            case 'selectedSkills':
              payload.skillsIds = this.formData.selectedSkills.map(skill => skill.id)
              break
            case 'selectedLists':
              payload.listsIds = this.formData.selectedLists
              break
            case 'duration':
              payload.duration = this.formData.duration.number
              payload.durationType = this.formData.duration.unit
              break
            case 'isPrivate':
              // Seletor de privacidade pergunta "é público?" para conteúdos business, invertendo a lógica
              payload.isPrivate = this.publishToIndividual ? this.formData.isPrivate : !this.formData.isPrivate
              break
            case 'coverImageURL':
              setCoverImageURL(this.formData.coverImageURL)
              break
            default:
              payload[key] = this.formData[key]
              break
          }
        })

        this.$store.commit('updateLastFiveSkillsUsed')

        if (this.selectedContentId) {
          this.updateCurrentContent(payload)
          return
        }
        this.createNewContent(payload)
      } else {
        this.$nextTick(() => {
          const hasErrorElements = document.querySelector('.validation--message')
          const top = hasErrorElements.offsetTop - 150
          document.querySelector('.v-dialog--active').scroll({
            top: top,
            behavior: 'smooth'
          })
          hasErrorElements.focus()
        })
      }
    },
    createNewContent (contentData) {
      this.$store.dispatch('attemptSaveContent', contentData)
        .then(({ data: contentResult }) => {
          this.postSaveContent(contentResult)
          this.isSaving = false
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('content.modal:create.toast.error')
          })
        })
        .finally(() => {
          this.isSaving = false
          this.closeContent()
        })
    },
    updateCurrentContent (contentData) {
      this.$store.dispatch('attemptUpdateContent', { contentData, contentId: this.selectedContentId })
        .then(({ data: contentResult }) => {
          this.postSaveContent(contentResult)
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('content.modal:create.toast.error')
          })
        })
        .finally(() => {
          this.isSaving = false
          this.closeContent()
        })
    },
    postSaveContent () {
      this.$store.dispatch('attemptGetMyselfContents', this.$store.getters.getCurrentContentFilters)
      this.updateLearningInProgress()
      this.$store.dispatch('attemptSetFeedbackMsg', {
        type: 'success',
        title: this.$t(`content.modal:${this.selectedContentId ? 'update' : 'create'}.toast.success.header`),
        description: this.$t(`content.modal:${this.selectedContentId ? 'update' : 'create'}.toast.success.body`)
      })
    },
    feedContentWithUrlMeta (data) {
      this.formData.link = data.url
      this.formData.title = data.title
      this.formData.description = data.description
      this.formData.coverImageURL = this.validateImageUrl(data.image)
      this.formData.coverImage = null
    },
    onStepOneConfirm (data) {
      this.step = this.step + 1
      this.feedContentWithUrlMeta(data)
    },
    validateImageUrl (url) {
      const anchor = document.createElement('a')
      anchor.href = url
      return anchor.host && anchor.host !== window.location.host ? url : ''
    }
  },
  watch: {
    skillSearch () {
      this.$store.dispatch('attemptGetAvailableSkills', { filterByName: this.skillSearch, limit: 999999 })
    },
    availableLists () {
      this.resizedLists = this.availableLists.filter(list => this.userManagesContent(list.workspaceId)).map(list => {
        return list.title.length < 20
          ? list
          : { ...list, title: list.title.slice(0, 20) + '...' }
      })
      this.resizedLists = this.addHeadersToList(this.resizedLists)
    },
    'formData.link' (newUrl, oldUrl) {
      if (oldUrl) {
        this.$store.dispatch('attemptExtractMetaInfos', newUrl)
          .then(({ data }) => {
            this.feedContentWithUrlMeta({ url: newUrl, ...data })
          })
      }
    },
    'formData.selectedSkills' (newSkills, oldSkills) {
      if (newSkills.length > oldSkills.length) {
        this.$store.commit('pushRecentSkill', newSkills.slice(-1)[0])
      } else {
        const skillRemoved = oldSkills.filter(old => newSkills.findIndex(newSkill => old.id === newSkill.id) === -1)[0]
        this.$store.commit('removeRecentSkill', skillRemoved)
      }
    }
  },
  created () {
    if (this.selectedContentId) {
      this.step = 2
    }
    this.$store.dispatch('attemptGetContentTypes')
    this.$store.dispatch('attemptGetOwnedLists')
      .then(data => {
        this.availableLists = data
      })
    this.$store.dispatch('attemptGetUserWorkspaces')
      .then(data => {
        this.userWorkspaces = data.filter(workspace => this.userManagesContent(workspace.id))
        this.availableProfiles = this.userWorkspaces.map(({ name, type, id }) => {
          if (!this.formData.workspaceId) {
            this.formData.workspaceId = id
          }
          switch (type) {
            case 'individual':
              if (!this.workspaceId) {
                this.formData.workspaceId = id
              }
              return { text: this.$t('content:publish.profile'), value: id }
            default:
              if (this.workspaceId === id) {
                this.formData.workspaceId = id
              }
              return { text: name, value: id }
          }
        })
      })
      .finally(() => {
        if (this.selectedContentId) {
          this.hydrateFormData()
          return
        }

        this.$store.dispatch('attemptGetMyselfLists')

        this.formData.contentTypeId = this.$route.params.contentTypeId || this.selectedContentTypeId
        this.formData.languageAlias = this.userInfo.locale || 'pt-BR'
        this.formData.duration.unit = 'minutes'
        this.formData.profile = this.$route.params.id
        if (this.userCanManage(this.selectedWorkspace.id)) {
          this.formData.workspaceId = this.selectedWorkspace.lxID
        }
      })
  }
}
</script>

<template>
  <v-dialog :value="true" width="600px" persistent>
    <v-card class="modal-content--container-wrapper">
      <div class="px-5 modal-content--container--header">
        <h3 class="text-left manage-content-title">
          {{ selectedContentId ? $t('content.modal:edit.content') : $t('content.modal:add.content') }}
          <span class="top-right"><v-btn icon @click="closeContent"><v-icon>mdi-close</v-icon></v-btn></span>
        </h3>
        <p class="text-left neutral-medium mb-0" v-if="!selectedContentId">{{ `${$t('global:step')} ${step} ${$t('global:of')} ${totalSteps}` }}</p>
      </div>
      <manage-content-step-one v-if="step === 1" @confirm="onStepOneConfirm" @close="closeContent" />
      <v-container v-else class="px-5 modal-content--container">
        <p class="text-left bold manage-content-info mt-0">
         {{ $t('content.label:url') }}
        </p>
        <p class="text-left neutral-medium mt-n4">{{ $t('content.info:url') }}</p>
        <input-field
          outlined
          readonly
          v-model="formData.link"
          :placeholder="$t('content.label:link')"
          :validation="$v.formData.link"
          :hide-validation="!$v.formData.link.$error && isMobile"
        />
        <p class="text-left bold manage-content-info mt-0">
          {{ $t('workspace.setup.step.4:.profile.image.title') }}
        </p>
        <image-upload
          :width="545"
          :height="200"
          :vWidth="isMobile ? 350 : 545"
          :vHeight="isMobile ? 160 : 200"
          :imageBackground="formData.coverImage || formData.coverImageURL || ''"
          :defaultImageBackground="getDefaultCoverImageBackground"
          @updateImage="changeImage"
          @removeImage="removeImage"
          :validation="$v.formData.coverImage"
          :hide-validation="!$v.formData.coverImage.$error && isMobile"
        />
        <p class="text-left bold manage-content-info mt-0">
          {{ $t('global:details') }}
        </p>
        <select-field
          outlined
          :items="getContentTypes"
          v-model="formData.contentTypeId"
          item-value="id"
          :label="$t('content.label:type')"
          :validation="$v.formData.contentTypeId"
          :hide-validation="!$v.formData.contentTypeId.$error && isMobile"
        />
        <input-field
          outlined
          v-model="formData.title"
          :placeholder="$t('content.label:title')"
          :validation="$v.formData.title"
          :hide-validation="!$v.formData.title.$error && isMobile"
        />
        <text-area
          v-model="formData.description"
          :placeholder="$t('content.label:description')"
          outlined
          :hide-validation="isMobile"
        />
        <select-field
          v-model="formData.languageAlias"
          :items="availableLanguages"
          :label="$t('content.label:language')"
          outlined
        />
        <v-row class="mt-n6">
          <v-col md="6">
            <input-field
              outlined
              type="number"
              min="1"
              step="1"
              :placeholder="$t('content.label:duration')"
              v-model="formData.duration.number"
              :validation="$v.formData.duration.number"
              :hide-validation="!$v.formData.duration.number.$error && isMobile"
            />
          </v-col>
          <v-col md="6">
            <select-field
              type="number"
              v-model="formData.duration.unit"
              :items="availableUnitsDuration"
              outlined
              :hide-validation="isMobile"
            />
          </v-col>
        </v-row>
        <select-field
          :placeholder="$t('content.label:level')"
          outlined
          v-model="formData.level"
          :items="availableLevels"
          :validation="$v.formData.level"
          :hide-validation="!$v.formData.level.$error && isMobile"
        ></select-field>
        <p class="text-left manage-content-info mb-n1 mt-0">
          {{ $t('global:skills') }}
        </p>
        <autocomplete
          multiple
          chips
          deletable-chips
          v-model="formData.selectedSkills"
          :items="resizedTitles"
          item-text="title"
          :item-value="t => t"
          :placeholder="$t('content.label:skills')"
          :search-input.sync="skillSearch"
          :hide-no-data="!skillSearch"
          :validation="$v.formData.selectedSkills"
          :hide-validation="!$v.formData.selectedSkills.$error && isMobile"
          @change="skillSearch = ''"
        >
          <template v-slot:no-data>
            <span/>
          </template>
          <template v-slot:append-item>
            <v-list-item v-if="canAddSkill" @click="handleSkillAdd">
              <v-list-item-icon class="mr-2"><v-icon class="primary-medium">mdi-plus</v-icon></v-list-item-icon>
              <v-list-item-content v-text="addNewSkill()"></v-list-item-content>
            </v-list-item>
          </template>
        </autocomplete>
        <last-five-skills-used :selectedSkills="formData.selectedSkills" @input="item => formData.selectedSkills.push(item)"/>
        <p class="text-left manage-content-info mb-n0 mt-0">
          {{ $t('global:publication') }}
        </p>
        <select-field
          v-if="availableProfiles"
          v-model="formData.workspaceId"
          :items="availableProfiles"
          outlined
          :hide-validation="isMobile"
        />
        <autocomplete
          v-model="formData.selectedLists"
          :items="resizedLists"
          multiple
          chips
          deletable-chips
          item-text="title"
          :placeholder="$t('content.label:list')"
          class="mt-n5"
          :menu-props="{ maxHeight: 150 }"
          item-value="id"
          >
          <template v-slot:no-data>
            <span/>
          </template>
        </autocomplete>
        <div v-if="publishToIndividual">
          <v-col cols="12" sm="12" md="12" class="mt-n5 manage-content--private-content-wrapper">
            <v-switch class="manage-content--private-switch" v-model="formData.isPrivate"/>
            <div class="manage-content--private-content-warning">
              <p class="text-left pt-2 is-private-label"><b>{{ $t('content:private') }}</b></p>
              <p class="text-left is-private-desc">{{ $t('content:private.warning') }}</p>
            </div>
          </v-col>
        </div>
        <div v-else>
          <v-col cols="12" sm="12" md="12" class="mt-n5 manage-content--private-content-wrapper">
            <v-switch class="manage-content--private-switch" v-model="formData.isPrivate"/>
            <div class="manage-content--private-content-warning">
              <p class="text-left pt-2 is-private-label"><b>{{ $t('content:publish.public') }}</b></p>
              <p class="text-left is-private-desc">{{ $t('content:discovery.warning') }}</p>
            </div>
          </v-col>
        </div>
        <div class="card-content-actions">
          <v-btn text @click="closeContent" color="#1200D3"><b>{{ $t('global:cancel') }}</b></v-btn>
          <v-btn dark @click="saveContent" :loading="isSaving" color="#1200D3"><b>{{ selectedContentId ? $t('global:save') : $t('global:add') }}</b></v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.modal-content--container-wrapper {
  overflow: hidden;
}
.modal-content--container--header {
  padding-top: 20px;
  .top-right {
    position: absolute;
    right: 12px;
    top: 12px;
  }
  .manage-content-title {
    font-family: $lato;
    font-size: 20px;
    font-weight: bold;
    .top-right {
      .v-icon {
        color: $primary-medium;
      }
    }
  }
  .manage-content-info {
    font-family: $lato;
    font-size: 16px;
    font-weight: bold;
    margin-top: 24px;
    color: $neutral-black;
    &.mt-0 {
      margin-top: 0;
    }
  }
}
.modal-content--container {
  overflow: auto;
  .v-subheader.theme--light {
    font-weight: 900;
    @extend .font-16;
  }
  .v-list-item__title {
    @extend .font-16;
  }
  .v-autocomplete:not(.v-input--is-focused).v-select--chips input { max-height: 25px !important; }
  .is-private-label {
    font-size: 14px;
    font-family: $lato;
    font-weight: bold;
    color: $neutral-black;
  }
  .is-private-desc {
    font-size: 14px;
    font-family: $lato;
    color: $neutral-dark;
    margin-top: -15px;
    width: 100%;
  }
  .card-content-actions {
    text-align: right;
    height: 60px;
    margin-top: 10px;
    .v-btn:nth-child(1) {
      margin-right: 8px;
    }
  }
  .manage-content--private-content-wrapper {
    display: flex;
    .manage-content--private-switch {
      margin-top: 4px;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 45px;
    .card-content-actions {
      margin-right: 0;
    }
  }
}
</style>
