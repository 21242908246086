<script>
export default {
  name: 'LastFiveSkillsUsed',
  props: {
    selectedSkills: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      lastFiveSkills: []
    }
  },
  methods: {
    isSkillSelected (skill) {
      return this.selectedSkills.findIndex(selected => selected.id === skill.id) === -1
    }
  },
  created () {
    this.lastFiveSkills = [...this.$store.getters.getLastFiveSkillsUsed]
  }
}
</script>
<template>
  <div class="last-five-skills-used--container" v-if="lastFiveSkills.length > 0">
    <p>{{ $t('global:skills.used.recently') }}</p>
    <div class="last-five-skills-used--skills-wrapper">
      <template v-for="skill in lastFiveSkills">
        <v-chip
          :key="skill.id"
          color="#1200D3"
          :class="{'selected': !isSkillSelected(skill)}"
          :ripple="isSkillSelected(skill)"
          :outlined="isSkillSelected(skill)"
          :dark="!isSkillSelected(skill)"
          @click="isSkillSelected(skill) && $emit('input', skill)"
        >
          <v-icon :color="isSkillSelected(skill) ? '#1200D3' : '#FFFFFF'">{{ isSkillSelected(skill) ? 'mdi-plus' : 'mdi-check' }}</v-icon>
          {{ skill.title }}
        </v-chip>
      </template>
    </div>
  </div>
</template>
<style lang="scss">
.last-five-skills-used--container {
  text-align: left;
  margin-bottom: 40px;
  p {
    margin-bottom: 5px;
  }
  .last-five-skills-used--skills-wrapper {
    > * {
      margin: 8px 8px 0 0;
      font-weight: bold;
      font-size: 14px;
      font-family: $lato;
      &.selected {
        cursor: default;
      }
    }
  }
}
</style>
