<script>
import { required, url } from 'vuelidate/lib/validators'
import InputField from '@/components/general/InputField'

export default {
  name: 'ManageContentStepOne',
  components: {
    InputField
  },
  data: () => ({
    dialog: true,
    contentUrl: null,
    isLoading: false
  }),
  validations: {
    contentUrl: {
      required,
      url
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isLoading = true
        this.$store.dispatch('attemptExtractMetaInfos', this.contentUrl)
          .then(({ data }) => {
            this.$emit('confirm', { url: this.contentUrl, ...data })
          })
          .catch(() => {
            this.$emit('confirm', { url: this.contentUrl })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>

<template>
  <v-container class="px-5 mt-n6 step-one-content--container">
    <p class="text-left bold step-one-content-info">
      {{ $t('content.label:url') }}
    </p>
    <p class="text-left neutral-medium mt-n4">{{ $t('content.info:url') }}</p>
    <input-field
      outlined
      v-model="contentUrl"
      :placeholder="$t('content.label:link')"
      :validation="$v.contentUrl"
      :hide-validation="!$v.contentUrl.$error && isMobile"
    />
    <div class="card-content-actions">
      <v-btn class="btn transform-unset" text @click="close" color="#1200D3"><b>{{ $t('global:cancel') }}</b></v-btn>
      <v-btn class="btn transform-unset" dark @click="confirm" :loading="isLoading" color="#1200D3"><b>{{ $t('global:advance') }}</b></v-btn>
    </div>
  </v-container>
</template>

<style lang="scss">
.step-one-content--container {
  padding-top: 20px;
  .card-content-actions {
    text-align: right;
    height: 60px;
    margin-top: 10px;
    .v-btn:nth-child(1) {
      margin-right: 8px;
    }
  }
  .step-one-content-info {
    font-family: $lato;
    font-size: 16px;
    font-weight: bold;
    margin-top: 24px;
    color: $neutral-black;
    &.mt-0 {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 45px;
    .card-content-actions {
      margin-right: 0;
    }
  }
}
</style>
